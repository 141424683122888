import React from 'react';
import './Contact.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

const Contact = () => {
  return (
    <section id="section5" className="contact-section">
      <h2> İletişim </h2>
      {/* Google Maps Iframe */}
      <div className="contact-map">
        <iframe
          title="İlker Teknik Konumu - Google Maps" 
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d55058.76781359197!2d27.18353542865927!3d38.43369614128755!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14b963462fe0d24f%3A0xc03da691da4a911a!2s%C4%B0lker%20Teknik!5e0!3m2!1str!2str!4v1726396245004!5m2!1str!2str"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>

      <div className="contact-container">
        <div className="contact-info">
          <div className="contact-item">
            <FontAwesomeIcon icon={faEnvelope} size="2x" />
            <h3>E-POSTA</h3>
            <p>info@ilkerteknik.com</p>
          </div>

          <div className="contact-item">
            <FontAwesomeIcon icon={faPhone} size="2x" />
            <h3>TELEFON</h3>
            <p>+90 (542) 411 73 96</p>
            <p>+90 (232) 436 63 35</p>
          </div>

          <div className="contact-item">
            <FontAwesomeIcon icon={faMapMarkerAlt} size="2x" />
            <h3>ADRES</h3>
            <p>Mansuroğlu Mahallesi, 294 Sokak No:14/3 <br />Bayraklı, İzmir, Türkiye</p>
          </div>
        </div>
      </div>

      <div className="contact-copyright">
        <p>© Copyright 2024 İlker Teknik Her hakkı saklıdır.</p>
      </div>
    </section>
  );
};

export default Contact;
