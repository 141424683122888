import React, { useState } from 'react';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons';
import AboutUs from './components/AboutUs';
import Products from './components/Products';
import References from './components/References';
import Contact from './components/Contact';

function App() {
  const images = ['image11.png', 'test.png'];
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    const isFirstImage = currentIndex === 0;
    const newIndex = isFirstImage ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastImage = currentIndex === images.length - 1;
    const newIndex = isLastImage ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: 'smooth' });
  };

  const handleNavClick = (event, sectionId) => {
    event.preventDefault();
    if (sectionId === 'top') {
      scrollToTop(); 
    } else {
      scrollToSection(sectionId);
    }
  };

  return (
    <div className="App">
      <header className="top-header">
        <div className="header-left">
          <p>info@ilkerteknik.com</p>
        </div>
        <div className="header-right">
    {/*<p><span role="img" aria-label="globe">🌍</span> <span className="bold-text">ENGLISH</span></p>  koys*/} 
        </div>
      </header>

      <div className="nav-bar">
        <div className="logo">
          <img src={process.env.PUBLIC_URL + '/nereyeatayim.png'} alt="Logo" className="logo-image" />
        </div>
        <nav className="nav-links">
  <button onClick={(e) => handleNavClick(e, 'top')}>ANASAYFA</button>
  <button onClick={(e) => handleNavClick(e, 'section2')}>HAKKIMIZDA</button>
  <button onClick={(e) => handleNavClick(e, 'section3')}>ÜRÜNLERİMİZ</button>
  <button onClick={(e) => handleNavClick(e, 'section4')}>REFERANSLAR</button>
  <button onClick={(e) => handleNavClick(e, 'section5')}>İLETİŞİM</button>
</nav>


        <div className="social-icons">
          <a href="https://www.linkedin.com/company/i%CC%87lker-teknik" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          <a href="https://www.youtube.com/@nereyeataym8479" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faYoutube} />
          </a>
        </div>
      </div>

      <div className="slider-container">
        <div className="arrow left-arrow" onClick={goToPrevious}>
          &#10094;
        </div>
        <img src={process.env.PUBLIC_URL + '/' + images[currentIndex]} alt="Slider" className="slider-image" />
        <div className="arrow right-arrow" onClick={goToNext}>
          &#10095;
        </div>
      </div>

      <AboutUs />
      <Products />
      <References />
      <Contact />
    </div>
  );
}

export default App;

