import React, { useState } from 'react';
import './References.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';


const References = () => {
    return(
      <section id="section4" className="references-section">
     <h2>Referanslar</h2>
      
      <h3>Bucak Belediyesi Projeleri</h3>
      <p>1) Çevre ve Şehircilik Bakanlığı ile iş birliği içerisinde, Burdur Bucak Belediyesi evsel atıklar, geri dönüştürülebilir atıklar ve bina/yerleşkelerin sahadaki konumları dijital harita üzerine işlenmiştir. Atık toplama ölçümleri yapılmış ve sonuçlar Mayıs 2021 tarihli Türkiye Belediyeler Birliği dergisinde yayınlanmıştır. Firmamızın geliştirdiği yazılım sayesinde temizlik bütçesinde bir önceki yıla göre %19'luk bir düşüş sağlanmıştır.</p>
      
      <p>2) Firmamız, Bucak Belediyesi için hem iOS hem de Android platformlarında çalışan <strong>"Bucak Sıfır Atık"</strong> mobil uygulamasını geliştirmiştir. Bu uygulama sayesinde Bucak Belediyesi, 2. Sıfır Atık Zirvesi'nde "Çevre ve Sürdürülebilirlik" kategorisinde birincilik ödülü almıştır.</p>
      
      <p><a href="https://www.bucak.bel.tr/haberler/BUCAK-SIFIR-ATIK-MOBIL-UYGULAMASI-ILE-KAREKODLU-TAKIP/2298" target="_blank" rel="noopener noreferrer">Bucak Sıfır Atık Mobil Uygulaması</a></p>
      <p><a href="https://www.bomba15.com/akdeniz-belediyeler-birliginden-bucak-belediyesine-juri-ozel-odulu" target="_blank" rel="noopener noreferrer">Jüri Özel Ödülü</a></p>
      <p><a href="https://www.cnnturk.com/yerel-haberler/burdur/bucak/bucaka-sifir-atik-zirvesinde-odul-1074197" target="_blank" rel="noopener noreferrer">Sıfır Atık Zirvesi Ödülü</a></p>

      <h3>Konak Belediyesi Projeleri</h3>
      <p>3) Konak Belediyesi, firmamızın geliştirdiği <strong>"Nereye Atayım"</strong> yazılımını kullanarak sokak toplayıcıları ile atık toplanmasını ve izlenmesini gerçekleştirmiştir. Bu projeye dair çok sayıda video ve haber internette yer almaktadır.</p>
      <p><a href="https://www.konak.bel.tr/haber/konak-belediyesinden-sokakta-donusume-bir-yenilik-daha-gezici-ambalaj-atigi-toplama-araci-yollarda" target="_blank" rel="noopener noreferrer">Konak Sokak Atık Toplama Yeniliği</a></p>
      <p><a href="https://www.mansetizmir.com/konak-belediyesi-nden-izmir-de-bir-ilk/65632/" target="_blank" rel="noopener noreferrer">İzmir Atık Toplama</a></p>
      <p><a href="https://ankahaber.net/haber/detay/konak_belediyesinden_sokakta_donusumde_yeni_uygulama_gezici_ambalaj_atigi_toplama_araci_yola_cikti_88947" target="_blank" rel="noopener noreferrer">Sokakta Atık Toplama Uygulaması</a></p>

      <h3>ÇEVKO Vakfı İş Birliği</h3>
      <p>4) Firmamız, ÇEVKO Vakfı ile birlikte yazılım geliştirme çalışmaları yapmıştır. Bu proje hakkında daha fazla bilgiye ÇEVKO Vakfı'nın resmi internet sitesinden ulaşabilirsiniz.</p>
      <p><a href="https://www.cevko.org.tr/index.php?option=com_content&view=article&id=1083&Itemid=176&lang=tr#:~:text=Nereye%20Atay%C4%B1m%20Saha%20At%C4%B1k%20Bilgi%20Y%C3%B6netim%20Sistemi%20Nedir%3F,ve%20verimlili%C4%9Fi%20artt%C4%B1ran%20yaz%C4%B1l%C4%B1m%20uygulamas%C4%B1d%C4%B1r." target="_blank" rel="noopener noreferrer">ÇEVKO Vakfı Projesi</a></p>

      <p>5) Projelerimize ait videoları izlemek için, <a href="https://www.youtube.com/channel/UCHJy9vV1m9vWd3xj44T3fDA" target="_blank" rel="noopener noreferrer">YouTube Kanalımıza</a> göz atabilirsiniz.</p>

      </section>

    );
  };
  

export default References;

