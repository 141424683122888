import './Products.css';
import React, { useEffect } from 'react';

const introductionText = `
“Nereye Atayım?" platformu, farklı kullanıcı gruplarının çeşitli ihtiyaçlarını karşılamak amacıyla geliştirilmiş bir uygulamadır. Uygulama: yetkilendirilmiş kuruluşlara, lisanslı firmalara, AVM’lere, zincir marketlere, organize sanayi bölgelerine, belediyelere, yerleşkelere, depolara ve vatandaşlara çeşitli hizmetler ve çözümler sunmaktadır.
`;

const operationsText = `
Uygulama, özellikle çok paydaşlı toplama operasyonları gibi karmaşık iş ve işlemleri kullanıcı dostu arayüzleri ve sistematiği ile kolaylıkla yönetilebilir hale getirir. Kullanıcılar kendi yetkileri dahilinde faaliyetleri gözlemleyebilir, talep açabilir, uygunsuzluk bildirebilir, araçları takip edebilir, iş emri atayabilir ve ilgili raporları sistemden çekebilir. Optimal araç rotalama gibi hizmetlerle kaynak kullanımını minimize eder ve sürdürülebilir, yeşil bir endüstri için hizmet eder. Bu sayede süreçlerin bütüncül bir şekilde izlenmesi ve yönetilmesi daha etkin bir şekilde gerçekleştirilebilir.
`;

const trackingText = `
Uygulama ekipman, atık, operasyon, araç ve ekipman takibi için farklı modüller ile kullanıcılarına çözümler sağlamaktadır. Farklı türlerdeki envanterlerin, araçların ve operasyonların; karbon ayak izi, ekipman miktarı, konum, maliyet gibi çeşitli parametrelerin anlık olarak gerçek verilerle dijital takibinin gerçekleştirilmesini sağlar. Takip edilen parametre ve veriler raporlama modülleri için saklanır. “Nereye Atayım?” uygulamasının istatistiksel analiz ve maliyet raporu modülleri haricinde, belirtilen tarih aralıklarında çeşitli parametreler için EXCEL formatında veri çekilmesi de mümkündür. Bu şekilde “Sıfır Atık” belgesi için uygun formatta veri alt yapısı sağlanır. Yönetmeliklere uygunluk sağlanması için gerekli sistematiği kullanıcılarına sunar.
`;

const platformText = `
Müşterilerimize maksimum kullanılabilirlik sunmak amacıyla, platform hem masaüstü bilgisayarlar hem de mobil cihazlar için özelleştirilmiş sürümler sunmaktadır. Bu çoklu platform desteği, kullanıcıların ihtiyaçlarına göre kesintisiz bir hizmet almalarını sağlar. Veri güvenliği son derece kritik bir unsurdur ve kesinlikle yetkilendirilmemiş kullanıcılara paylaşılmamaktadır.
`;

const collectionOperationsText = `
Envanterlerin toplanması ve bırakılması gibi işlemlerinin takip edilmesi kurumlar için en zorlayıcı süreçlerden biridir. Tesis dışında gerçekleştirilen operasyonların “Nereye Atayım?” platformu sayesinde anlık olarak izleme ve yönetme fırsatı kullanıcılara mümkün kılınmıştır. Ekipmanlar, envanterler, araçlar, personeller ve paydaşlar sisteme ekosisteminin doğası analiz edilerek işenir. Kullanıcıların dilekleri doğrultusunda ilgili konfigürasyonlar gerçekleştirilir. Toplama operasyonlarını açılan talepler üzerine veya talep sistematiği ile çalışmayan ekosistemlerde doluluk tahminleme modülleri ile gerçekleştirilebilir.
Dijital harita üzerinden ekipmanlar özellikleri ve konum bilgileri tanımlanır. Envanterler/atıklar cinslerine göre tanımlanır. Sistem gerçekleştirilen operasyonların kare kodlar ve tartım ekipmanları ile düzenli olarak doğrulanmasını sağlar. Bu sayede gerçek, anlık ve doğrulanmış veriler kullanıcıları sunulur.
`;

const vehicleTrackingText = `
Yazılım, araçların vize ve periyodik bakım gibi işlemlerini hafızasında tutar. Kullanıcıları ilgili tarihlerin yaklaşmasında robot operatör ile bilgilendirir. Toplama işlemleri esnasında, dijital harita üzerinden en son hangi noktadan işlem gerçekleştirildiğini kullanıcılar ile anlık olarak paylaşır. Yakıt ve km bilgileri de sürekli olarak sisteme işlenir.
`;

const carbonFootprintText = `
Operasyon bazında araçların karbon ayak izi hesaplanır. Salınan karbon miktarı bilgilerinin belgelendirilmesi mümkün hale gelir. Raporlama modülleri ile operasyon bazında karbon ayak izi kullanıcılara sunulur.
`;

const routingText = `
 “Nereye Atayım?” yakıt tüketimini minimize etmek ve operasyonları maksimum verim ile yönetmek için rotalama hizmetini kullanıcılarına sunar. Google Maps üzerinden kullanıcıya menzili çapında en yakın noktaları sıralar. Bu şekilde, yakıt tasarrufu sağlanır ve karbon salınımı asgari düzeye indirilir.
`;

const fullnessText = `
 Doluluk, kullanıcılarının var olan tartım ekipmanlarına göre esnek bir şekilde entegre edilebilir. Temelde iki ana metodoloji üzerinde gerçekleşir.
1. Anlık Tartım veya Araç Üstü Tartım Ekipmanına Sahip Olan Sistemler: Her toplama operasyonu esnasında tartımlar gerçekleştirilir. Mobil uygulama ve tartım ekipmanı iletişimi sayesinde miktar bilgileri gerçek verilerle anlık olarak sisteme yansır. Toplama ekipmanının yazılımının arkasındaki ampirik formüller sayesinde dolma frekansı hesaplamaları sisteme yansıtılır. Kullanıcılar ekipmanlar belirli bir doluluk oranını geçtiği zaman sistem tarafından uyarılır. Verilerin sisteme dijital olarak işlenmesinin yanı sıra tartım noktalarında ilgili ekipmanlarla entegre edilerek manuel olarak fiş basılabilmektedir.
2. Araç Kantarı ile Başlangıç & Bitiş Tartımı Gerçekleştirilen Sistemler: Toplama işlemleri gerçekleştirilmeden önce aracın kantar ile tartımı gerçekleştirilir ve personel tarafından sisteme işlenir. Süreç dahilinde ilgili personel ekipmanın doluluk oranını göz ile ekspertiz eder ve tahmini doluluk oranını sisteme işler. Operasyonlar tamamlandıktan sonra, araç yeniden kantar ile tartılır. Ardından, yazılım alanında uzman kişiler tarafından geliştirilmiş tahminleme formülleri ile; envanterlerin hacim, lokasyon, ekspertiz parametreleri gibi girdileri kapsayarak tahminleme işlemlerini gerçekleştirir. Verilerin sisteme dijital olarak işlenmesinin yanı sıra tartım noktalarında ilgili ekipmanlarla entegre edilerek manuel olarak fiş basılabilmektedir.
`;

const inventoryTracking = `
Ekipmanların saysı, lokasyonları ve türleri bazında takibi tamamen dijital bir şekilde gerçekleştirilebilmektedir. Kaynakların doğrulanmış verilerle takibi gerçekleşir. Çok paydaşlı projeler ve operasyonlar için “Nereye Atayım?” bir köprü mekanizması oluşturur.
`;

const AccidentNotifications = `
Uygulama paydaşların anlık olarak uygunsuzlukları, kazaları ve benzeri durumları fotoğraf gibi destekleyici ögelerle yetkili kişilere bildirilmesini sağlar. Bu sayede olası problemlerin kaynaklarının saptanması ve iyileştirme çalışmaları için gerekli alt yapı “Nereye Atayım?” uygulaması ile mümkündür.
`;


const AccidentNotifications1 = `
Organize Sanayi Bölgeleri, binalar, yerleşkeler, kamplar, depolar ve zincir marketler gibi çeşitli işletme türleri için tasarlanmış yazılımımız, bir dizi önemli fayda sunmaktadır.
Yazılımımız, atık ve envanter yönetimi süreçlerini optimize etmek için mükemmel bir araçtır. İşletmeler, türüne göre envanter miktarını takip edebilir ve atık yönetimini daha etkili hale getirebilirler. Kuruluşlar, belirli bölgelerde veya faaliyet noktalarında gerçekleştirilen operasyonları izleyebilirler. Bu, işletmelerin operasyonlarını daha iyi planlamalarına ve optimize etmelerine yardımcı olur. “Nereye Atayım?”, işletmelerin operasyonlarına ve atık yönetimine bağlı maliyetleri izlemelerine ve analiz etmelerine olanak tanır.
Bertaraf için takip edilebilirlik sağlanır. Her işetmenin/fabrikanın/kurumun geri dönüştürülen atık miktarının takibi belgelendirilebilir konuma gelir. Nitelikli sıfır atık belgesi başvurusu için gerekli belgeme gerçekleştirilir. Bertaraf süreçlerinin izlenmesi, işletmelerin çevresel düzenlemelere uyum sağlamasına yardımcı olur. Bu, olası cezaları önler ve işletmenin çevresel uyumunu güvence altına alır. Atık miktarlarının izlenmesi, işletmelerin sıfır atık hedeflerine ulaşmalarını kolaylaştırır.
“Nereye Atayım?”, iş emirlerini dijital olarak oluşturmayı ve yönetmeyi sağlar. Ayrıca, envanter ve toplama ekipmanlarının doluluk oranlarına dayalı olarak daha verimli rotalar çizilmesine olanak tanır. Bu, işletmelerin operasyonlarını daha verimli hale getirir.
Uygulama, arızalı toplama ekipmanları veya araçların hızlı bir şekilde tespit edilmesine ve kayıt altına alınmasına olanak tanır. Bu, bakımın düzenli ve sürdürülebilir bir şekilde yönetilmesini sağlar. Kurumlar, istedikleri raporları oluşturabilir ve bu raporları kolayca paylaşabilirler. Ayrıca, istenirse raporlar Excel formatına aktarılabilir.
`;

const AccidentNotifications2 = `
Günümüzde çevresel sürdürülebilirlik ve atık azaltma konuları giderek daha fazla önem kazanmaktadır. Sıfır Atık Yönetmeliği gerekliliklerini karşılayan ve işletmelerin, kurumların ve belediyelerin atık yönetimini daha etkin hale getirmelerine yardımcı olan özel bir yazılımı tanıtmaktan gurur duyuyoruz. Yazılım sayesinde atık izlemesi, analizi, akış yönetimi ve raporlaması mümkün hale gelir. Atık yönetimi süreçlerinizi iyileştirmek ve sıfır atık hedeflerinizi başarıyla uygulamak için bu çözümü tercih ederek hem çevreye katkıda bulunabilir hem de işletmenizin veya kurumunuzun itibarını artırabilirsiniz.
`;

const reportingFeaturesText = `
Belirtilen tarih aralığında, yazılımımız kullanıcılarına çeşitli raporlama ve analiz imkanları sunmaktadır. Bu özellikler sayesinde kullanıcılar aşağıdaki verileri kolayca elde edebilirler:
1. Envanterlerin türüne göre miktar bilgileri raporlanır.
2. Belirtilen bina/yerleşke/depo/proje faaliyet noktasından yapılan operasyon sayısını ve operasyon noktaları raporlanır.
3. Belirtilen bina/yerleşke/depo/proje faaliyet noktasından yapılan atık veya envanter alımının miktar bilgileri işlenir.
4. Personelin yaptığı operasyonlar ve işlediği envanter/atık miktar bilgileri raporlanır.
5. Araçların yakıt tüketim raporları, mali istatistiklerle birlikte kullanıcıya sunulur.
6. Arızalı toplama ekipmanı veya araçların mobil uygulama ile masaüstü sistemine aktarılması ve kayıt altına alınarak bakımların düzenli ve sürdürülebilir bir şekilde yapılması sağlanır.
7. Operasyon bazında maliyet raporları oluşturulur.
8. Operasyon bazında karbon ayak izi raporları oluşturulur.
9. İstenilen raporların Excel formatına aktarılabilmesi mümkün hale getirilir.
10. Talep izleme raporlarının oluşturulması "Nereye Atayım?" yazılımı ile kolaylaştırılır.

Ayrıca, yazılım verimliliği artırmak için çeşitli avantajları sunar:
- İş emirlerinin dijital olarak verilmesine olanak sağlanır.
- Maliyet analizleri yapılarak maliyetlerin düşürülmesine yardımcı olur.
- Personellerin verimlilik raporları ile somut bir şekilde değerlendirilmesine imkan tanır ve "Nereye Atayım?" projesi ile verimlilik artırılır.
`;

const AccidentNotifications3 = `
Maliye Bakanlığı Gelir İdaresi Başkanlığı tarafından, alıcı ve satıcı firmaların e-irsaliyeleri düzenlemelerine imkan tanınmaktadır. Bu şekilde, alıcı ve satıcı firmalar ön muhasebe işlemlerini tamamlayabilmektedirler. Düzenlenen e-irsaliyeler anlık olarak Maliye Bakanlığı'na iletilmekte ve tüm muhasebe programlarıyla sorunsuz bir şekilde uyumlu çalışmaktadır. Muhasebe işlemleri entegratör firmalar üzerinden gerçekleştirilmektedir.
`;

const na = `
Vatandaşlar uygulamadan en yakın atık toplama ekipmanını görüntülemek için faydalanabilirler. İlgili belediye, lisanslı firma veya kurum sistem üzerinden tanımladığı ekipmanı ulaşabilir bir duruma getirir. Bu şekilde atıkların kaynaklarında ayrıştırılması ve geri kazanım bilincinin topluma kazandırılması ile ilgili önemli adımlar atılabilir. Ekipmanlar ile ilgili talepler, uygunsuzluklar sistem üzerinden fotoğraflar ve açıklamalar ile iletilebilir. Bu sayede, iyileştirme çalışmaları için veri zemini oluşturulur.
`;

const collector = `
Belediye tarafından Sokak Toplayıcılarına ‘’Bağımsız Sıfır Atık Kartı’’ çıkarılır. Atık toplayıcılarının Nereye Atayım sistemine tanımlanması ile belediye, lisanlı firma, sokak toplayıcıları kooperatifi ve sokak toplayıcıları veri aktarım ile uyumlu ve koordineli çalışır.
Doluluk bildiriminde bulunan sokak toplayıcısının konumuna veya belirlenen toplama noktasına toplama aracı gelir. Mobil uygulama ile Bağımsız Sıfır Atık Kartı okutulur, toplama atacı üzerindeki kantar ile atık tartımı yapılır mobil uygulama üzerinden Nereye Atayım Sistemine tartım bilgisi aktarılır.
Tartım bilgisi belediye, lisanlı firma, sokak toplayıcıları kooperatifi tarafından anlık raporlanır.
`;

const ekspertiz = `
Toplama aşamasında mobil uygulama üzerinden atığın fotoğrafı çekilerek atıkta bulunan yabancı madde bilgisi, fire oranı belirtilerek sistem kaydı alınır.
`;
const ReportingFeatures = () => {
  return (
    <div>
      {reportingFeaturesText.split('\n').map((line, index) => (
        <p key={index}>
          {line}
        </p>
      ))}
    </div>
  );
};




const Products = () => {
  useEffect(() => {
    // Tüm image-container'ları bul
    const containers = document.querySelectorAll('.image-container');
    containers.forEach(container => {
      const images = container.querySelectorAll('.product-image');
      // Eğer container içinde 1 fotoğraf varsa 'single-image' sınıfı ekle
      if (images.length === 1) {
        container.classList.add('single-image');
      }
    });
  }, []);
  return (
    <section id="section3" className="products-section">
      <div className="products-container">
        <h1>Uygulama Hakkında</h1>
        <p>{introductionText}</p>
        <p>{operationsText}</p>
        <p>{trackingText}</p>
        <p>{platformText}</p>

        {/* Image Section */}
        <div className="image-container">
          <img src={process.env.PUBLIC_URL + '/image1.png'} alt="Application Overview" className="product-image" />
          <img src={process.env.PUBLIC_URL + '/image2.png'} alt="Sustainability" className="product-image" />
        </div>

        {/* Additional Text */}
        <h2>Toplama Operasyonları</h2>
        <p>{collectionOperationsText}</p>

        {/* Additional Image Section */}
        <div className="image-container">
          <img src={process.env.PUBLIC_URL + '/image3.png'} alt="Collection Operation" className="product-image" />
          <img src={process.env.PUBLIC_URL + '/image4.png'} alt="Inventory Tracking" className="product-image" />
        </div>

        {/* Vehicle Tracking Section */}
        <h2>Araç Takibi</h2>
        <p>{vehicleTrackingText}</p>

        {/* Image Section for Vehicle Tracking */}
        <div className="image-container">
          <img src={process.env.PUBLIC_URL + '/image5.png'} alt="Vehicle Tracking" className="product-image" />
        </div>

        {/* Carbon Footprint Section */}
        <h2>Karbon Ayak İzi Hesaplamaları</h2>
        <p>{carbonFootprintText}</p>

        {/* Image Section for Carbon Footprint */}
        <div className="image-container">
          <img src={process.env.PUBLIC_URL + '/image7.png'} alt="Carbon Footprint" className="product-image" />
        </div>

        {/* Routing Section */}
        <h2>Rotalama</h2>
        <p>{routingText}</p>

        {/* Image Section for Routing */}
        <div className="image-container">
          <img src={process.env.PUBLIC_URL + '/image15.png'} alt="Routing Map" className="product-image" />
          <img src={process.env.PUBLIC_URL + '/image9.png'} alt="Fuel Efficiency" className="product-image" />
        </div>

        {/* Fullness Section */}
        <h2>Doluluk</h2>
        <p>{fullnessText}</p>

        {/* Image Section for Fullness */}
        <div className="image-container">
          <img src={process.env.PUBLIC_URL + '/image14.png'} alt="Weighing Process" className="product-image" />
        </div>
        {/* Inventory Tracking Section*/}
        <h2>Ekipman Takibi</h2>
        <p>{inventoryTracking}</p>

        {/* Image Section for inventory tracking */}
        <div className="image-container">
          <img src={process.env.PUBLIC_URL + '/image16.png'} alt="Fullness System" className="product-image" />
          <img src={process.env.PUBLIC_URL + '/image17.png'} alt="Weighing Process" className="product-image" />
        </div>

        {/*AccidentNotifications Section*/}
        <h2>Uygunsuzluk/ Kaza / Hata Bildirimleri:</h2>
        <p>{AccidentNotifications}</p>

        {/* Image Section for AccidentNotifications Section*/}
        <div className="image-container">
          <img src={process.env.PUBLIC_URL + '/image18.png'} alt="Fullness System" className="product-image" />
        </div>


        {/*AccidentNotifications1 Section*/}
        <h2>Organize Sanayi Bölgeleri / Bina-Yerleşke / Kampüs / Depo / Zincir Marketler:</h2>
        <p>{AccidentNotifications1}</p>

        {/* Image Section for AccidentNotifications1 Section*/}
        <div className="image-container">
          <img src={process.env.PUBLIC_URL + '/image19.png'} alt="Fullness System" className="product-image" />
        </div>

        {/*AccidentNotifications2 Section*/}
        <h2>Sıfır Atık Yönetmeliği İle Uyum:</h2>
        <p>{AccidentNotifications2}</p>

        {/* Image Section for AccidentNotifications2 Section*/}
        <div className="image-container">
          <img src={process.env.PUBLIC_URL + '/image20.png'} alt="Fullness System" className="product-image" />

        </div>

        {/*reportingFeaturesText Section*/}
        <h2>Raporlama ve Analiz Modülleri:</h2>
        <ReportingFeatures />
        {/* Image Section for reportingFeaturesText Section*/}
        <div className="image-container">
          <img src={process.env.PUBLIC_URL + '/image21.png'} alt="Fullness System" className="product-image" />
          <img src={process.env.PUBLIC_URL + '/image22.png'} alt="Fullness System" className="product-image" />
          <img src={process.env.PUBLIC_URL + '/image23.png'} alt="Fullness System" className="product-image" />
          <img src={process.env.PUBLIC_URL + '/image24.png'} alt="Fullness System" className="product-image" />

        </div>

        {/*AccidentNotifications3 Section*/}
        <h2>Ön Muhasebe ve E-İrsaliye:</h2>
        <p>{AccidentNotifications3}</p>

        {/* Image Section for AccidentNotifications2 Section*/}
        <div className="image-container">
          <img src={process.env.PUBLIC_URL + '/image25.png'} alt="Fullness System" className="product-image" />

        </div>

        {/*na Section*/}
        <h2>Vatandaşlar için “NEREYE ATAYIM?”:</h2>
        <p>{na}</p>

        {/* Image Section for na Section*/}
        <div className="image-container">
        <img src={process.env.PUBLIC_URL + '/image26.png'} alt="Fullness System" className="product-image" />

        </div>

        {/*collector Section*/}
        <h2>Sokak Toplayıcıları Entegrasyonu</h2>
        <p>{collector}</p>

        {/* Image Section for collector Section*/}
        <div className="image-container">
          <img src={process.env.PUBLIC_URL + '/image27.png'} alt="Fullness System" className="product-image" />
          <img src={process.env.PUBLIC_URL + '/image28.png'} alt="Fullness System" className="product-image" />
          <img src={process.env.PUBLIC_URL + '/image29.png'} alt="Fullness System" className="product-image" />
          <img src={process.env.PUBLIC_URL + '/image30.png'} alt="Fullness System" className="product-image" />


        </div>

        {/*ekspertiz Section*/}
        <h2>Atık Ekspertizi</h2>
        <p>{ekspertiz}</p>

        {/* Image Section for ekspertiz Section*/}
        <div className="image-container">
          <img src={process.env.PUBLIC_URL + '/image31.png'} alt="Fullness System" className="product-image" />
        </div>
      </div>
    </section>
  );
};

export default Products;
