import React from 'react';
import './AboutUs.css'; 

// Long text content
const aboutUsText = `
Nereye Atayım, 2017 yılında ambalaj atık ekipmanlarının elektronik veri tabanı üzerinden takip ve izleme sistemini gerçekleştirmek amacıyla oluşturulmuştur.
Çok kısa sürede ‘ilkleri gerçekleştirme’ ilkesini benimsemiş, teknolojik yatırımları ve öncü girişimleri  ile sektördeki önemli sıkıntıları kendisine hedef seçmiştir. 
Bu bağlamda Nereye Atayım olarak vizyonumuz izleme, takip ve elektronik veri kayıtlarının oluşturulması ile verileri anlamlı raporlar sunarak sektördeki verimliliği arttırmak adına katkı sağlamaktır.
`;

const AboutUs = () => {
  return (
    <section id="section2" className="hakkimizda-section">
      <div className="container">
        <div className="left-column">
          <h1>Hakkımızda</h1>
          <p>{aboutUsText}</p>
        </div>

        <div className="right-column">
          <img src={process.env.PUBLIC_URL + '/image8.png'} alt="Recycling" />
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
